import * as tslib_1 from "tslib";
const ɵ0 = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
    return import("./syndication/whitelabel/whitelabel.module.ngfactory").then(m => m.WhiteLabelModuleNgFactory);
});
export const ApplicationRoutes = [
    { path: '', redirectTo: 'brandedservices', pathMatch: 'full' },
    {
        path: 'brandedservices',
        loadChildren: ɵ0
    }
];
export { ɵ0 };
