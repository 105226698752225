import * as tslib_1 from "tslib";
import { GrantAccessPermissions } from '../../../shared/models/GrantAccessPermissions';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { WhiteLabelDataService } from '../services/whitelabel-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/whitelabel-data.service";
import * as i2 from "../../../shared/services/file-upload.service";
export class MarketplaceConfigService {
    constructor(whiteLabelDataService, fileUploadService) {
        this.whiteLabelDataService = whiteLabelDataService;
        this.fileUploadService = fileUploadService;
    }
    getMarketplaceConfigWhiteLabelSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const marketplaceConfigSettings = yield this.whiteLabelDataService.getMarketplaceConfigSettings().toPromise();
            if (!marketplaceConfigSettings) {
                return null;
            }
            // Build CustomUrl section of the Form model.
            const pageUrl = marketplaceConfigSettings.pageUrl || '';
            //
            // We limit the range of custom subdomain to 50. Because Azure has a limit of 89 characters for a domain name.
            // Therefore after .configureyourorder.com has been appended we only have 64 characters available.
            //
            const skCustomSubDomainRegex = /^(https:\/\/)([a-zA-Z0-9-]{3,50})\.configureyourorder\.com\/?$/;
            const skCustomSubDomainNameRegexGroupIndex = 2;
            const subDomainUrlMatches = pageUrl.match(skCustomSubDomainRegex);
            const skCustomUrl = {
                skCustomUrlSelection: (subDomainUrlMatches ? 'subdomain' : 'domain'),
                skDomainName: subDomainUrlMatches ? '' : pageUrl,
                skSubDomainName: subDomainUrlMatches ? subDomainUrlMatches[skCustomSubDomainNameRegexGroupIndex] : '',
                skCertificate: marketplaceConfigSettings.domainCertificate || null,
                skSelectedSslCert: null,
                skSslPassphrase: ''
            };
            // Build CustomerSupport section of the Form model.
            const supportDetails = marketplaceConfigSettings.supportDetails;
            const supportOption = marketplaceConfigSettings.supportUrl ? 'support-url' : 'support-details';
            const skCustomerSupport = {
                skSupportOptions: supportOption,
                skSupportEmail: supportDetails ? supportDetails.emailAddress : '',
                skSupportPhone: supportDetails ? supportDetails.phoneNumber : '',
                skSupportHours: supportDetails ? supportDetails.hours : '',
                skSupportUrl: marketplaceConfigSettings.supportUrl || ''
            };
            /**
             * TODO: Revisit GrantAccessPermissions to account for all 3 options.
             * Currently we only handle FullAccess or NoAccess but we should support all the options.
             */
            const grantFullAccess = marketplaceConfigSettings.grantAccessPermissions === GrantAccessPermissions.FullAccess;
            // Put together the form model.
            const formModel = {
                skPageTitle: marketplaceConfigSettings.pageTitle || '',
                skCustomUrl,
                skCustomerSupport,
                skIsEnabled: marketplaceConfigSettings.isEnabled || false,
                skGrantAccessPermission: grantFullAccess
            };
            return formModel;
        });
    }
    saveMarketplaceConfigWhiteLabelSettings(newSettings, publish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Determine the pageUrl
            const isSubDomainOption = newSettings.skCustomUrl.skCustomUrlSelection === 'subdomain';
            const subDomainUrl = `https://${newSettings.skCustomUrl.skSubDomainName}.configureyourorder.com/`;
            const domainUrl = newSettings.skCustomUrl.skDomainName;
            const pageUrl = isSubDomainOption ? subDomainUrl : domainUrl;
            // Determine the certificate
            const certData = isSubDomainOption ? undefined : btoa(yield this.fileUploadService.readAllBytes(newSettings.skCustomUrl.skSelectedSslCert));
            const domainCertificate = isSubDomainOption ? undefined : { passphrase: newSettings.skCustomUrl.skSslPassphrase };
            // Determine the Support Option
            const isSupportUrlOption = newSettings.skCustomerSupport.skSupportOptions === 'support-url';
            const supportDetails = isSupportUrlOption ? undefined : {
                emailAddress: newSettings.skCustomerSupport.skSupportEmail,
                phoneNumber: newSettings.skCustomerSupport.skSupportPhone,
                hours: newSettings.skCustomerSupport.skSupportHours
            };
            const supportUrl = isSupportUrlOption ? newSettings.skCustomerSupport.skSupportUrl : undefined;
            // Determine the access permissions
            const grantAccessPermissions = newSettings.skGrantAccessPermission ?
                GrantAccessPermissions.FullAccess : GrantAccessPermissions.NoAccess;
            // Build the request object
            const marketplaceConfigPartnerSettingsRequest = {
                certData,
                marketplaceConfigSettings: {
                    domainCertificate,
                    isEnabled: publish,
                    pageTitle: newSettings.skPageTitle,
                    pageUrl,
                    supportDetails,
                    supportUrl,
                    grantAccessPermissions
                }
            };
            return this.whiteLabelDataService.saveMarketplaceConfigSettings(marketplaceConfigPartnerSettingsRequest).toPromise();
        });
    }
}
MarketplaceConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MarketplaceConfigService_Factory() { return new MarketplaceConfigService(i0.ɵɵinject(i1.WhiteLabelDataService), i0.ɵɵinject(i2.FileUploadService)); }, token: MarketplaceConfigService, providedIn: "root" });
