import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
/**
 * Automatically intercepts http requests and routes them through the sewr system.
 * @remarks Sewr system can be bypassed by adding the SEWRInterceptor.bypassSewrProxy property to a request headers.
 */
export class SEWRInterceptor {
    constructor() {
        /** RegExp(s) used for bypassing the sewr sytem when requesting application assets over http. */
        this.IgnoreAssets = /^\/assets/;
        /**
         * Ignoring absolute URLs as other links from common components / packages
         * could have absolute links and we don't want those links to go through SEWR
         */
        this.IgnoreAbsoluteURL = /^https?:\/\//;
    }
    intercept(request, next) {
        const bypassSewr = request.headers.has(SEWRInterceptor.BypassSewrProxy);
        if (this.IgnoreAssets.test(request.url) || bypassSewr || this.IgnoreAbsoluteURL.test(request.url)) {
            const sewrBypassedRequest = request.clone({
                headers: request.headers.delete(SEWRInterceptor.BypassSewrProxy),
                withCredentials: true
            });
            return next.handle(sewrBypassedRequest);
        }
        // Proxy all other requests through sewr
        const sewrRoutedRequest = request.clone({
            url: `${environment.sewr}/pipe${request.url}`,
            withCredentials: true
        });
        return next.handle(sewrRoutedRequest);
    }
}
/** The property to add to the http header in order to prevent proxying to the sewr system. */
SEWRInterceptor.BypassSewrProxy = 'X-Bypass-Sewr';
SEWRInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SEWRInterceptor_Factory() { return new SEWRInterceptor(); }, token: SEWRInterceptor, providedIn: "root" });
