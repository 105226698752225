import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationArea, PlatformNavigationComponent } from '@skykick/core';
import { Intercom } from 'ng-intercom';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
export class AppComponent {
    constructor(translateService, intercom, userService) {
        this.translateService = translateService;
        this.intercom = intercom;
        this.userService = userService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const user = this.userService.getCurrentUser();
            this.intercom.boot({
                app_id: 'itg40p5r',
                email: user.email,
                user_id: user.id,
                partner_id: user.partnerId,
                name: user.fullName,
                avatar: {
                    type: 'avatar',
                    image_url: user.picture
                },
                is_admin: user.hasPermission('partnerportaladmin')
            });
        });
    }
    ngAfterViewInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.skPlatformNavigation.SkNavigationArea = NavigationArea.BrandedServices;
        });
    }
}
