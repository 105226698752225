import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService } from '@skykick/core';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
export class PublishComponent {
    constructor(modalService, translateService) {
        this.modalService = modalService;
        this.translateService = translateService;
        this.publish = new EventEmitter();
        this.preview = new EventEmitter();
        this.unpublish = new EventEmitter();
    }
    get skStatus() {
        return !this.skPublished ? 'draft' : 'published';
    }
    get skVisibility() {
        return !this.skVisibility ? 'private' : 'public';
    }
    confirmUnpublish() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modalResult = this.modalService.open(ConfirmationModalComponent, { size: 'lg' });
            modalResult.componentInstance.Title = yield this.translateService
                .get('COMPONENTS.PUBLISH.UNPUBLISH_CONFIRMATION_TITLE')
                .toPromise();
            modalResult.componentInstance.Message = yield this.translateService
                .get('COMPONENTS.PUBLISH.UNPUBLISH_CONFIRMATION_BODY')
                .toPromise();
            const unpublish = (yield modalResult.result).data;
            if (unpublish) {
                this.unpublish.emit();
            }
        });
    }
}
