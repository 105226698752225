import { CloudBackupComponent } from './cloud-backup/cloud-backup.component';
import { MarketplaceConfigComponent } from './marketplace-config/marketplace-config.component';
import { SettingsComponent } from './settings/settings.component';
import { WhiteLabelComponent } from './whitelabel.component';
const ɵ0 = {
    type: 'secondary',
    name: 'COMMON.CLOUD_BACKUP'
}, ɵ1 = {
    type: 'secondary',
    name: 'COMMON.MARKETPLACE_ORDER'
}, ɵ2 = {
    type: 'secondary',
    name: 'COMMON.SETTINGS'
};
export const WhiteLabelRoutes = [
    {
        path: 'brandedservices',
        component: WhiteLabelComponent,
        children: [
            {
                path: '',
                redirectTo: 'backup',
                pathMatch: 'full'
            },
            {
                path: 'backup',
                component: CloudBackupComponent,
                data: ɵ0
            },
            {
                path: 'marketplace',
                component: MarketplaceConfigComponent,
                data: ɵ1
            },
            {
                path: SettingsComponent.ComponentName,
                component: SettingsComponent,
                data: ɵ2
            }
        ]
    }
];
export { ɵ0, ɵ1, ɵ2 };
