import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
export class FileUploadService {
    readAllBytes(file) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!file) {
                return Promise.resolve('');
            }
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onloadend = _ => {
                    if (reader.result) {
                        resolve(reader.result);
                    }
                    else {
                        reject(reader.error);
                    }
                };
            });
        });
    }
}
FileUploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUploadService_Factory() { return new FileUploadService(); }, token: FileUploadService, providedIn: "root" });
