import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickModalService, SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { ToastrService } from 'ngx-toastr';
import { BetaFlag } from '../../../core/infrastructure/beta-flags';
import { BrandSettingsService } from '../settings/settings.service';
import { CustomUrlComponent } from '../shared/custom-url/custom-url.component';
import { CustomerSupportComponent } from '../shared/customer-support/customer-support.component';
import { MarketplaceConfigPreviewDialogComponent } from './marketplace-config-preview-dialog/marketplace-config-preview-dialog.component';
import { MarketplaceConfigService } from './marketplace-config.service';
export class MarketplaceConfigComponent extends SkyKickProductPage {
    constructor(taskManagerService, activatedRoute, userService, formBuilder, toastrService, modalService, marketplaceConfigService, brandSettingsService, translateService) {
        super(taskManagerService);
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.toastrService = toastrService;
        this.modalService = modalService;
        this.marketplaceConfigService = marketplaceConfigService;
        this.brandSettingsService = brandSettingsService;
        this.translateService = translateService;
        this.isLoading = true;
        this.skFirstSave = true;
        this.skPublished = false;
        const currentUser = userService.getCurrentUser();
        this.skShowRequestAccessPage = !currentUser.hasPermission(BetaFlag.MarketplaceSyndication);
    }
    get skPageTitle() {
        const skPageTitle = this.skMarketplaceConfigForm.get('skPageTitle');
        if (!skPageTitle) {
            return null;
        }
        return skPageTitle.value;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Construct the form.
            this.skMarketplaceConfigForm = this.formBuilder.group({
                skPageTitle: ['', [Validators.required]],
                skCustomUrl: this.formBuilder.group({
                    skCustomUrlSelection: this.formBuilder.control('subdomain', [Validators.required]),
                    skSubDomainName: this.formBuilder.control(''),
                    skDomainName: this.formBuilder.control(''),
                    skCertificate: this.formBuilder.control(''),
                    skSelectedSslCert: this.formBuilder.control(''),
                    skSslPassphrase: this.formBuilder.control('')
                }),
                skCustomerSupport: this.formBuilder.group({
                    skSupportOptions: this.formBuilder.control('support-details', [Validators.required]),
                    // by default we require support-details
                    skSupportEmail: this.formBuilder.control('', [Validators.required]),
                    skSupportPhone: this.formBuilder.control('', [Validators.required]),
                    skSupportHours: this.formBuilder.control('', [Validators.required]),
                    skSupportUrl: this.formBuilder.control('')
                }),
                skGrantAccessPermission: [false, [Validators.required]]
            });
        });
    }
    ngAfterViewInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Initialize Child Form Groups with data if available.
            try {
                if (this.skShowRequestAccessPage) {
                    return;
                }
                this.isLoading = true;
                const marketplaceConfigSettings = yield this.marketplaceConfigService.getMarketplaceConfigWhiteLabelSettings();
                if (!marketplaceConfigSettings) {
                    this.skFirstSave = true;
                    this.skPublished = false;
                    return;
                }
                this.skFirstSave = false;
                this.skPublished = marketplaceConfigSettings.skIsEnabled || false;
                this.skMarketplaceConfigForm.patchValue(marketplaceConfigSettings);
                this.skMarketplaceConfigForm.get('skCustomUrl.skCustomUrlSelection').updateValueAndValidity();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.isLoading = false;
            }
        });
    }
    onSubmit(publish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.validateRequirements();
            if (this.skMarketplaceConfigForm.invalid) {
                return;
            }
            const marketPlaceConfigFormData = this.skMarketplaceConfigForm.getRawValue();
            try {
                this.isLoading = true;
                yield this.marketplaceConfigService.saveMarketplaceConfigWhiteLabelSettings(marketPlaceConfigFormData, publish);
                this.skPublished = publish;
                this.skFirstSave = false;
                const successTitle = yield this.translateService.get('COMMON.SUCCESS').toPromise();
                const successMessage = yield this.translateService.get('COMMON.SAVED_SETTINGS').toPromise();
                this.toastrService.success(successMessage, successTitle);
            }
            catch (ex) {
                const failureTitle = yield this.translateService.get('COMMON.FAILURE').toPromise();
                const failureMessage = yield this.translateService.get('ERRORS.ERROR_SAVED_SETTINGS').toPromise();
                let toastMessage = failureMessage;
                // Check if the exception is an HttpErrorResponse.
                if (!(ex instanceof HttpErrorResponse)) {
                    this.toastrService.error(toastMessage, failureTitle);
                    return;
                }
                const certificateNotValidStatusCode = 460;
                const customUriInUseStatusCode = 461;
                const skCustomUrlFormGroup = this.skMarketplaceConfigForm.controls['skCustomUrl'];
                const errors = { incorrect: true };
                if (ex.status === customUriInUseStatusCode) {
                    const skDomainNameControl = skCustomUrlFormGroup.controls['skDomainName'];
                    const skSubDomainNameControl = skCustomUrlFormGroup.controls['skSubDomainName'];
                    // Determine domain option so that we can mark the correct FormControl as incorrect
                    if (this.skCustomUrl.isSubDomainOption) {
                        skDomainNameControl.setErrors(errors);
                    }
                    else {
                        skSubDomainNameControl.setErrors(errors);
                    }
                }
                else if (ex.status === certificateNotValidStatusCode) {
                    const skSslPassphrase = skCustomUrlFormGroup.controls['skSslPassphrase'];
                    skSslPassphrase.setErrors(errors);
                }
                // Check to see if the exception has an error property and that the error property is a string.
                const hasErrorMessage = !!ex.error && typeof (ex.error) === 'string';
                toastMessage = hasErrorMessage ? ex.error : toastMessage;
                this.toastrService.error(toastMessage, failureTitle);
            }
            finally {
                this.isLoading = false;
            }
        });
    }
    onPreview() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.isLoading = true;
                this.validateRequirements();
                const marketPlaceConfigFormData = this.skMarketplaceConfigForm.getRawValue();
                const brandSettings = yield this.brandSettingsService.getBrandSettings();
                this.isLoading = false;
                // Constructs then opens the modal view
                const skykickModal = this.modalService
                    .open(MarketplaceConfigPreviewDialogComponent, { size: 'xl' });
                // Note: NgbModalOptions doesn't let us pass init data, therefore we have to pass data using the componentInstance reference.
                const marketplaceConfigPreviewDialog = skykickModal.componentInstance;
                const customerSupportInfo = marketPlaceConfigFormData.skCustomerSupport;
                if (brandSettings) {
                    marketplaceConfigPreviewDialog.skCompanyName = brandSettings.companyName;
                    marketplaceConfigPreviewDialog.skLogoUrl = brandSettings.companyLogoUri;
                }
                marketplaceConfigPreviewDialog.skPublished = this.skPublished;
                marketplaceConfigPreviewDialog.skSupportOption = customerSupportInfo.skSupportOptions;
                if (customerSupportInfo.skSupportOptions === 'support-details') {
                    marketplaceConfigPreviewDialog.skCustomerSupportInfo = {
                        emailAddress: customerSupportInfo.skSupportEmail,
                        hours: customerSupportInfo.skSupportHours,
                        phoneNumber: customerSupportInfo.skSupportPhone
                    };
                }
                else {
                    marketplaceConfigPreviewDialog.skCustomerSupportInfo = customerSupportInfo.skSupportUrl;
                }
                // Publish changes if the user clicked the publish button.
                yield skykickModal.result.then((result) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const publish = result.data;
                    return publish ? this.onSubmit(true) : Promise.resolve();
                }));
            }
            finally {
                this.isLoading = false;
            }
        });
    }
    validateRequirements() {
        // Force the control and all child controls to recheck themselves for validity.
        this.skMarketplaceConfigForm.get('skCustomUrl.skCustomUrlSelection').updateValueAndValidity();
        const skPageTitle = this.skMarketplaceConfigForm.get('skPageTitle');
        if (skPageTitle) {
            skPageTitle.markAsDirty();
        }
        this.skCustomUrl.validateRequirements();
        this.skCustomerSupport.validateRequirements();
    }
}
