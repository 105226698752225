import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WhiteLabelDataService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    /**
     * @returns BackupWhiteLabelSettings or null if no WhiteLabelBackupSettings are found.
     */
    getBackupSettings() {
        return this.httpClient.get('/papi/partnersettings/backup');
    }
    /**
     * @returns Certificate information or null if no Certificate was uploaded.
     */
    saveBackupSettings(backupPartnerSettingsRequest) {
        return this.httpClient.post('/papi/partnersettings/backup', backupPartnerSettingsRequest);
    }
    getMarketplaceConfigSettings() {
        return this.httpClient.get('/papi/partnersettings/marketplacecfg');
    }
    saveMarketplaceConfigSettings(marketplaceConfigPartnerSettingsRequest) {
        return this.httpClient.post('/papi/partnersettings/marketplacecfg', marketplaceConfigPartnerSettingsRequest);
    }
    getBrandSettings() {
        return this.httpClient.get('/papi/partnersettings/brand');
    }
    /**
     * Save brand assets for a partner
     */
    saveBrandSettings(brandSettings) {
        return this.httpClient.post('/papi/partnersettings/brand', brandSettings);
    }
    /**
     * Upload a file to blob storage
     */
    uploadFileToBlob(fileUploadRequest) {
        return this.httpClient.post('/papi/partner/files', fileUploadRequest);
    }
}
WhiteLabelDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WhiteLabelDataService_Factory() { return new WhiteLabelDataService(i0.ɵɵinject(i1.HttpClient)); }, token: WhiteLabelDataService, providedIn: "root" });
