/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./welcome-modal.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "ngx-cookie-service";
var styles_WelcomeModalComponent = [i0.styles];
var RenderType_WelcomeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomeModalComponent, data: {} });
export { RenderType_WelcomeModalComponent as RenderType_WelcomeModalComponent };
export function View_WelcomeModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "modal-content text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "sk-example p-a-3 m-b-2 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "rounded"], ["src", "../../../../assets/images/full-width-sample.jpg"], ["width", "98%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [["class", "text-xs-center mt-3 mb-3 px-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", "sk-body-display card-text text-xs-center mb-3 px-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "dl", [["class", "sk-dl col-sm-10 col-sm-offset-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "modal-footer d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-primary center-block"], ["data-dismiss", "modal"], ["id", "skSyndicationWelcomeModal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.savePreviewModalState() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("WELCOME_MODAL.TITLE")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("WELCOME_MODAL.TEXT")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("WELCOME_MODAL.BUTTON_TEXT")); _ck(_v, 14, 0, currVal_2); }); }
export function View_WelcomeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sk-welcome-modal", [], null, null, null, View_WelcomeModalComponent_0, RenderType_WelcomeModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.WelcomeModalComponent, [i4.NgbActiveModal, i5.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WelcomeModalComponentNgFactory = i1.ɵccf("sk-welcome-modal", i3.WelcomeModalComponent, View_WelcomeModalComponent_Host_0, {}, {}, []);
export { WelcomeModalComponentNgFactory as WelcomeModalComponentNgFactory };
