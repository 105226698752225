import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { SkyKickProductPage, TaskManagerService } from '@skykick/core';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { BrandSettingsService } from './settings.service';
export class SettingsComponent extends SkyKickProductPage {
    constructor(taskManagerService, formBuilder, sanitizer, fileUploadService, brandSettingsService, toastrService, translateService) {
        super(taskManagerService);
        this.formBuilder = formBuilder;
        this.sanitizer = sanitizer;
        this.fileUploadService = fileUploadService;
        this.brandSettingsService = brandSettingsService;
        this.toastrService = toastrService;
        this.translateService = translateService;
        /**
         * The maximum allowed file size for favicon in Bytes.
         */
        this._skMaxFaviconSize = 300000;
    }
    ngOnInit() {
        this.skBrandAssetsForm = this.formBuilder.group({
            skCompanyName: ['', Validators.required],
            skPageFavicon: ['']
        });
    }
    ngAfterViewInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const brandSettings = yield this.brandSettingsService.getBrandSettings();
                if (!brandSettings) {
                    return;
                }
                this.skBrandAssetsForm.patchValue({ skCompanyName: brandSettings.companyName, skFaviconUrl: brandSettings.faviconLogoUri });
                if (brandSettings.faviconLogoUri) {
                    this.skFaviconUrl = brandSettings.faviconLogoUri;
                    this.previewFaviconFileName = 'favicon.ico';
                }
            }
            catch (err) {
                console.error('Unable to fetch brand assets');
            }
        });
    }
    get pageFaviconFileName() {
        const favicon = this.skBrandAssetsForm.get('skPageFavicon');
        if (!favicon) {
            return null;
        }
        const faviconFile = favicon.value;
        return faviconFile.name;
    }
    pageFaviconFileChange(selectedFiles) {
        if (!selectedFiles || selectedFiles.length <= 0) {
            return;
        }
        const selectedFile = selectedFiles.item(0);
        if (!selectedFile || selectedFile.size > this._skMaxFaviconSize) {
            this.skBrandAssetsForm
                .setErrors({ incorrect: true });
            this.customValidationMessage = 'ERRORS.FILE_LIMIT';
            return;
        }
        if (!this.skBrandAssetsForm) {
            return;
        }
        // preview favicon
        const mimeType = selectedFile.type;
        if (mimeType.match(/image\/*/) === null) {
            this.skBrandAssetsForm
                .setErrors({ incorrect: true });
            this.customValidationMessage = 'ERRORS.FILE_TYPE';
            return;
        }
        const reader = new FileReader();
        reader.onload = _event => {
            this.skFaviconUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result);
        };
        reader.readAsDataURL(selectedFile);
        this.faviconFile = selectedFile;
    }
    onSubmit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isLoading = true;
            const faviconFile = this.faviconFile;
            const companyName = this.skBrandAssetsForm.get('skCompanyName');
            if (!companyName) {
                return;
            }
            let faviconUrl = '';
            if (faviconFile) {
                const faviconRawData = btoa(yield this.fileUploadService.readAllBytes(faviconFile));
                const fileUploadRequest = {
                    fileData: faviconRawData,
                    fileName: faviconFile.name,
                    fileType: 'favicon'
                };
                try {
                    const fileUploadResponse = yield this.brandSettingsService.uploadFavicon(fileUploadRequest);
                    if (!fileUploadResponse) {
                        throw new Error('No response from file upload request');
                    }
                    faviconUrl = fileUploadResponse.fileUri;
                }
                catch (_a) {
                    console.log('Unable to upload favicon');
                    this.skBrandAssetsForm
                        .setErrors({ incorrect: true });
                    this.customValidationMessage = 'ERRORS.FAVICON_FAILURE';
                    this.isLoading = false;
                    return;
                }
            }
            const brandSettings = {
                companyName: companyName.value,
                faviconLogoUri: faviconUrl || '',
                companyLogoUri: ''
            };
            try {
                yield this.brandSettingsService.saveBrandSettings(brandSettings);
                const successTitle = yield this.translateService.get('COMMON.SUCCESS').toPromise();
                const successMessage = yield this.translateService.get('COMPONENTS.BRAND_ASSETS.SAVED_SETTINGS').toPromise();
                this.toastrService.success(successMessage, successTitle);
            }
            catch (_b) {
                this.skBrandAssetsForm
                    .setErrors({ incorrect: true });
                console.log('Unable to save brand settings');
                this.customValidationMessage = 'ERRORS.BRANDSETTINGS_SAVE';
            }
            finally {
                this.isLoading = false;
            }
        });
    }
}
/**
 * The name of the component.
 */
SettingsComponent.ComponentName = 'settings';
