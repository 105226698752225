/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';

import { SkyKickUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { SyndicationEnvironment } from './syndication-environment';

export const environment: SyndicationEnvironment = {
    production: false,
    userProvider: SkyKickUserProvider,
    sewr: 'https://route.skykick.com',
    webCore: 'https://sk-web-core-dev0.azurewebsites.net'
};
