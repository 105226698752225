import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import * as i0 from "@angular/core";
import * as i1 from "@skykick/platform-identity-auth-auth0-angular";
import * as i2 from "@ngx-translate/core";
export class InitializationService {
    constructor(userProvider, translateService) {
        this.userProvider = userProvider;
        this.translateService = translateService;
    }
    run() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            ///
            /// WARNING: This is intentionally undeveloped.  Entire
            ///          login page redirection and JWT validation
            ///          system needs a real design.
            ///          CM #76699 / PS #70222
            ///
            const portalPageThatWillRedirectToLoginPage = this.getLoginRedirect();
            try {
                this.userProvider.getCurrentUser();
                return Promise.resolve();
            }
            catch (e) {
                // The currently logged in user doesn't exist or their session has expired
                const loginExpiredTranslation = yield this.translateService.get('ERRORS.LOGIN_EXPIRED').toPromise();
                alert(loginExpiredTranslation);
                window.location.href = portalPageThatWillRedirectToLoginPage;
                return Promise.reject(new Error('User is not logged into the portal'));
            }
        });
    }
    getLoginRedirect() {
        const currentUrl = window.location.href.toLocaleLowerCase();
        // intentionally hard-coded and bad - see warning above.
        if (currentUrl.includes('localhost')) {
            return 'http://localhost:4000/';
        }
        else if (currentUrl.includes('dev0')) {
            return 'http://sk-dev0-portal.skykick.com';
        }
        else if (currentUrl.includes('dev4')) {
            return 'http://sk-dev4-portal.skykick.com';
        }
        else if (currentUrl.includes('dev')) {
            return 'http://sk-dev9-portal.skykick.com';
        }
        else {
            return 'https://portal.skykick.com';
        }
    }
}
InitializationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InitializationService_Factory() { return new InitializationService(i0.ɵɵinject(i1.AbstractUserProvider), i0.ɵɵinject(i2.TranslateService)); }, token: InitializationService, providedIn: "root" });
