import * as tslib_1 from "tslib";
import { HttpLoaderFactory, LanguageLoader } from './i18n/translation-loader-factory';
import { InitializationService } from './routing/login/initialization.service';
const ɵ0 = HttpLoaderFactory, ɵ1 = LanguageLoader, ɵ2 = (initializationService) => () => tslib_1.__awaiter(this, void 0, void 0, function* () { return initializationService.run(); });
/**
 *
 */
export class CoreModule {
    constructor(core) {
        if (core) {
            // CoreModule should only be loaded a single time within AppModule.
            throw new Error('CoreModule already loaded.');
        }
    }
}
export { ɵ0, ɵ1, ɵ2 };
