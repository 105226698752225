import * as tslib_1 from "tslib";
import { WhiteLabelDataService } from '../services/whitelabel-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/whitelabel-data.service";
export class BrandSettingsService {
    constructor(whiteLabelDataService) {
        this.whiteLabelDataService = whiteLabelDataService;
    }
    /**
     * Retrieves brand settings
     */
    getBrandSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.whiteLabelDataService.getBrandSettings().toPromise();
        });
    }
    /**
     * Saves brand settings
     */
    saveBrandSettings(brandSettings) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.whiteLabelDataService.saveBrandSettings(brandSettings).toPromise();
        });
    }
    /**
     * Upload favicon
     */
    uploadFavicon(fileUploadRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.whiteLabelDataService.uploadFileToBlob(fileUploadRequest).toPromise();
        });
    }
}
BrandSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandSettingsService_Factory() { return new BrandSettingsService(i0.ɵɵinject(i1.WhiteLabelDataService)); }, token: BrandSettingsService, providedIn: "root" });
