import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationArea, NavigationPermission, PlatformNavigationComponent } from '@skykick/core';
import { Intercom } from 'ng-intercom';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';

@Component({
    // tslint:disable-next-line: component-selector
    selector: '[sk-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        readonly translateService: TranslateService,
        private intercom: Intercom,
        private userService: AbstractUserProvider
    ) { }

    @ViewChild(PlatformNavigationComponent, { static: false })
    public skPlatformNavigation: PlatformNavigationComponent;

    async ngOnInit(): Promise<void> {
        const user = this.userService.getCurrentUser();

        this.intercom.boot({
            app_id: 'itg40p5r',
            email: user.email,
            user_id: user.id,
            partner_id: user.partnerId,
            name: user.fullName,
            avatar: {
                type: 'avatar',
                image_url: user.picture
            },
            is_admin: user.hasPermission('partnerportaladmin')
        });
    }

    async ngAfterViewInit(): Promise<void> {
        this.skPlatformNavigation.SkNavigationArea = NavigationArea.BrandedServices;
    }
}
