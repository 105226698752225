import * as tslib_1 from "tslib";
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { WhiteLabelDataService } from '../services/whitelabel-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/whitelabel-data.service";
import * as i2 from "../../../shared/services/file-upload.service";
export class CloudBackupService {
    constructor(whiteLabelDataService, fileUploadService) {
        this.whiteLabelDataService = whiteLabelDataService;
        this.fileUploadService = fileUploadService;
    }
    getBackupWhiteLabelSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const backupPartnerSettings = yield this.whiteLabelDataService.getBackupSettings().toPromise();
            if (!backupPartnerSettings) {
                return null;
            }
            // Build CustomUrl section of the Form model.
            const pageUrl = backupPartnerSettings.pageUrl || '';
            //
            // We limit the range of custom subdomain to 50. Because Azure has a limit of 89 characters for a domain name.
            // Therefore after .backupmycloud.com has been appended we only have 71 characters available.
            //
            const skCustomSubDomainRegex = /^(https:\/\/)([a-zA-Z0-9-]{1,50})\.backupmycloud\.com\/?$/;
            const skCustomSubDomainNameRegexGroupIndex = 2;
            const subDomainUrlMatches = pageUrl.match(skCustomSubDomainRegex);
            const skCustomUrl = {
                skCustomUrlSelection: (subDomainUrlMatches ? 'subdomain' : 'domain'),
                skDomainName: subDomainUrlMatches ? '' : pageUrl,
                skSubDomainName: subDomainUrlMatches ? subDomainUrlMatches[skCustomSubDomainNameRegexGroupIndex] : '',
                skCertificate: backupPartnerSettings.domainCertificate || null,
                skSelectedSslCert: null,
                skSslPassphrase: ''
            };
            // Build CustomerSupport section of the Form model.
            const supportDetails = backupPartnerSettings.supportDetails;
            const supportOption = backupPartnerSettings.supportUrl ? 'support-url' : 'support-details';
            const skCustomerSupport = {
                skSupportOptions: supportOption,
                skSupportEmail: supportDetails ? supportDetails.emailAddress : '',
                skSupportPhone: supportDetails ? supportDetails.phoneNumber : '',
                skSupportHours: supportDetails ? supportDetails.hours : '',
                skSupportUrl: backupPartnerSettings.supportUrl || ''
            };
            // Put together the form model.
            const formModel = {
                skPageTitle: backupPartnerSettings.pageTitle || '',
                skCustomUrl,
                skCustomerSupport,
                skLearnMoreUrl: backupPartnerSettings.learnMoreUrl || '',
                skIsEnabled: backupPartnerSettings.isEnabled || false
            };
            return formModel;
        });
    }
    saveBackupWhiteLabelSettings(newSettings, publish) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // Determine the pageUrl
            const isSubDomainOption = newSettings.skCustomUrl.skCustomUrlSelection === 'subdomain';
            const subDomainUrl = `https://${newSettings.skCustomUrl.skSubDomainName}.backupmycloud.com/`;
            const domainUrl = newSettings.skCustomUrl.skDomainName;
            const pageUrl = isSubDomainOption ? subDomainUrl : domainUrl;
            const certData = isSubDomainOption ? undefined :
                btoa(yield this.fileUploadService.readAllBytes(newSettings.skCustomUrl.skSelectedSslCert));
            const domainCertificate = isSubDomainOption ? undefined : {
                passphrase: newSettings.skCustomUrl.skSslPassphrase
            };
            // Determine Support Option
            const isSupportUrlOption = newSettings.skCustomerSupport.skSupportOptions === 'support-url';
            const supportDetails = isSupportUrlOption ? undefined : {
                emailAddress: newSettings.skCustomerSupport.skSupportEmail,
                phoneNumber: newSettings.skCustomerSupport.skSupportPhone,
                hours: newSettings.skCustomerSupport.skSupportHours
            };
            const supportUrl = isSupportUrlOption ? newSettings.skCustomerSupport.skSupportUrl : undefined;
            // Build the request object
            const backupPartnerSettingsRequest = {
                certData,
                backupWhiteLabelSettings: {
                    domainCertificate,
                    isEnabled: publish,
                    pageTitle: newSettings.skPageTitle,
                    pageUrl,
                    supportDetails,
                    supportUrl,
                    learnMoreUrl: newSettings.skLearnMoreUrl || ''
                }
            };
            return this.whiteLabelDataService.saveBackupSettings(backupPartnerSettingsRequest).toPromise();
        });
    }
}
CloudBackupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CloudBackupService_Factory() { return new CloudBackupService(i0.ɵɵinject(i1.WhiteLabelDataService), i0.ɵɵinject(i2.FileUploadService)); }, token: CloudBackupService, providedIn: "root" });
